import React from 'react';
import { Container } from 'styles';
import * as S from 'styles/Home/styled';

const Comunity = () => {
  return (
    <S.ComunitySection>
      <Container>
        <S.SectionTitle>
          O <strong>Vagas.com</strong> é a maior comunidade de candidatos do
          Brasil
        </S.SectionTitle>
        <S.SectionParagraph>
          Temos posições para profissionais do mercado de tecnologia, como
          desenvolvedores, designers, marketing, negócios <br />e comercial nas
          principais capitais do país.
        </S.SectionParagraph>
        <S.ComunityContainer>
          <S.ComunityBlock>
            <i
              className="bx bxs-mouse-alt bx-lg box-icon"
              title="Audiência"
            ></i>
            <h3>Audiência</h3>
            <p>
              Maior site de carreira do <br /> Brasil e 7º do mundo! São 36{' '}
              <br /> milhões de visitas por mês.
            </p>
          </S.ComunityBlock>
          <S.ComunityBlock>
            <i
              className="bx bxs-file-find bx-lg box-icon"
              title="Tecnologia"
            ></i>
            <h3>Tecnologia</h3>
            <p>
              Nossos algoritmos <br /> encontram as vagas de <br /> emprego
              perfeitas para você!
            </p>
          </S.ComunityBlock>
          <S.ComunityBlock>
            <i className="bx bxs-bolt bx-lg box-icon" title="Agilidade"></i>
            <h3>Agilidade</h3>
            <p>
              Seu perfil 100% gratuito, <br /> disponível para mais de <br /> 16
              mil recrutadores do país.
            </p>
          </S.ComunityBlock>
          <S.ComunityBlock>
            <i className="bx bxs-building bx-lg box-icon" title="Qualidade"></i>
            <h3>Qualidade</h3>
            <p>
              Vagas nas maiores <br /> e melhores empresas <br />e startups
              brasileiras.
            </p>
          </S.ComunityBlock>
        </S.ComunityContainer>
      </Container>
    </S.ComunitySection>
  );
};

export default Comunity;
